<template>
	<div id="header-wrapper" data-scroll="0">
		<header class="header">
			<main :class="['wrapper-base', 'header__wrapper', {'_mobile': showMenuMobile}]">
        <div class="header-top">
          <div class="header__side" @click.capture="showMenuMobile = false">
            <strong class="header__logo" title="DataForce" @click="handleLogoClick">
              <span class="visually-hidden">DataForce</span>
              <img src="@/assets/img/landing/logo.svg" alt="DataForce" loading="lazy" class="img-content">
            </strong>
          </div>
          <v-button
              :class="['header__menu-button', menuBtnClass]"
              type="text-base"
              @click="toggleMenu"
              width="40px"
              padding-right="0"
              padding-left="0"
          >
            <icon :name="showMenuMobile ? 'close' : 'menu'" width="24px" height="24px" fill="#000"></icon>
          </v-button>
        </div>
				<div :class="headerBlockClass">
					<ul :class="headerLinksClass">
						<li
								v-for="(link, i) of headerLinks"
								:key="i"
								@click.capture="showMenuMobile = false"
						>
							<router-link
									v-if="$route.name !== ROUTE_NAME.INDEX && link.to || link.to && link.to.name !== ROUTE_NAME.INDEX"
									class="header__body-link"
									:to="link.to"
									@click.native="handleRouterLinkClick"
							>
								{{ link.title }}
							</router-link>
							<a
								v-else
								class="header__body-link"
								:href="link.href || link.to?.hash"
								:target="link.target || ''"
								@click.prevent="openLink(link)"
							>
								{{ link.title }}
							</a>
						</li>
					</ul>
					<div class="header__body-buttons">
						<v-button
                href="https://my.dataforce.io/login"
								type="text-base"
								class="header__action-btn"
								name="войти"
								width="100%"
								height="32px"
						/>
						<v-button
								:to="{ name: ROUTE_NAME.DEMO }"
								type="dataforce"
								class="header__action-btn"
								name="Демо"
								width="100%"
								height="32px"
								dataforce
						/>
					</div>
				</div>
			</main>
		</header>
	</div>
</template>

<script>
import scroll from '@/landing/helpers/scroll.js'
import ROUTE_NAME from '@/router/routeName.js'
export default {
		mixins: [scroll],
		data: () => ({
				menuMobile: false,

				showMenuMobile: false,
				ROUTE_NAME
		}),

		computed: {
				config () {
					return this.$store.state.config
				},
				headerLinks () {
					return [
						{
								title: 'Возможности',
								to: {
										name: ROUTE_NAME.INDEX,
										hash: '#capabilities'
								}
						},
						{
								title: 'Данные',
								to: {
										name: ROUTE_NAME.INDEX,
										hash: '#data'
								}
						},
						{
								title: 'Стоимость',
								to: {
										name: ROUTE_NAME.INDEX,
										hash: '#cost'
								}
						},
						{
								title: 'Материалы',
								to: {
										name: ROUTE_NAME.INDEX,
										hash: '#publications'
								}
						},
						{
								title: 'Презентация',
								href: `${this.config.api.dataforce}/help/df_presentation.pdf`,
								target: '_blank'
						}
					]
				},
				menuBtnClass () {
						let classList = []
						if (this.showMenuMobile) {
								classList.push('_close')
						}

						return classList
				},

				headerBlockClass () {
						let classList = ['header__body-block']
						if (this.showMenuMobile) {
								classList.push('_mobile')
						}

						return classList
				},

				headerLinksClass () {
						let classList = ['header__body-links']
						if (this.showMenuMobile) {
								classList.push('_mobile')
						}

						return classList
				}
		},

		created () {
				document.documentElement.style.scrollBehavior = 'smooth'
		},

		mounted() {
				document.addEventListener('keydown', this.closeMenu)

				const debounce = (fn) => {
				let frame

				return (...params) => {
					if (frame) {
						cancelAnimationFrame(frame)
					}

					frame = requestAnimationFrame(() => {
						fn(...params)
					})
				}
			}

			const storeScroll = () => {
				const header = document.getElementById('header-wrapper')
				if (header) {
					header.dataset.scroll = window && window.scrollY
				}
			}

			document && document.addEventListener('scroll', debounce(storeScroll), { passive: true })
			document && storeScroll()
		},

		destroyed () {
				document.documentElement.style = undefined
				document.removeEventListener('keydown', this.closeMenu)
		},

		methods: {
				toggleMenu () {
						this.showMenuMobile ^= true
				},

				closeMenu (e) {
						const escKeyCode = ['Escape', 'Esc']
						if (this.showMenuMobile) {
								if (
										('key' in e && escKeyCode.includes(e.key)) ||
										e.keyCode === 27
								) {
										this.showMenuMobile = false
								}
						}
				}
		}
}
</script>

<style lang="scss" scoped>
#header-wrapper {
	background: #fff;
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 100%;
	min-width: 320px;
	z-index: 100;

	&:not([data-scroll='0']) {
    box-shadow: 0 0 4px rgba(146,157,176,.2);
    position: fixed;
		overflow: hidden;
  }

	.header {
			-webkit-transition: padding .25s ease 0s,-webkit-box-shadow .25s ease 0s;
			transition: padding .25s ease 0s,-webkit-box-shadow .25s ease 0s;
			transition: box-shadow .25s ease 0s,padding .25s ease 0s;
			transition: box-shadow .25s ease 0s,padding .25s ease 0s,-webkit-box-shadow .25s ease 0s;
			will-change: box-shadow,padding;
	}

	.header._sticky {
			padding-top: 16px;
			padding-bottom: 16px;
	}

	@media screen and (max-width:1130px) {
			.header {
					-webkit-transition: -webkit-box-shadow .25s ease 0s;
					transition: -webkit-box-shadow .25s ease 0s;
					transition: box-shadow .25s ease 0s;
					transition: box-shadow .25s ease 0s,-webkit-box-shadow .25s ease 0s;
					will-change: box-shadow;
			}
	}

	.header__wrapper {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			min-height: 32px;
			justify-content: space-between;

    &._mobile {
      align-items: flex-start;
      height: 100vh;
      padding-top: 34px;
    }
	}

	.header__menu-button {
			display: none;

			@media screen and (max-width: 1130px) {
					display: flex;
					align-items: center;
					width: 34px !important;
					height: 34px;
			}
	}

	.header__logo {
			display: block;
			width: 126px;
			height: 16px;
			color: #2979ff;
			position: relative;
			object-fit: contain;
			cursor: pointer;

			&-seowork {
					text-decoration: none;
					color: #455165;
					font-weight: 400;
					position: absolute;
					bottom: -20px;
					right: -20px;
					font-size: 13.5px;
			}
	}

	.header__logo-output {
			fill: currentColor;
			display: block;
			width: 100%;
			height: 100%;
	}

	.header__side,.header__side-wide {
			-ms-flex-negative: 1;
			flex-shrink: 1;
	}

	.header__side-wide {
			width: 300px;
			min-width: 300px;
	}

	.header__body {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-flex: 1;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			width: 1%;

			&-block {
					display: flex;
					flex: 1;
					@media screen and (max-width: 1130px) {
							display: none;

							&._mobile {
									position: fixed;
									top: 0;
									bottom: 0;
									left: 0;
									right: 0;
									margin-top: 70px;
									min-height: calc(100dvh - 70px);
									max-height: calc(100dvh - 70px);
									overflow-y: scroll;
									background: #fff;
									z-index: 2;
									display: grid;
									grid-template-rows: 1fr auto;
									overflow: hidden;
									transform: translate(0);
									visibility: visible;
							}
					}

					.header__body-buttons {
							display: flex;
							margin: 28px;
					}
			}
	}

	.header__body-links {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;

			li {
					margin-left: 16px;
			}

			@media screen and (max-width: 1130px) {
					&._mobile {
							display: flex;
							align-items: flex-start;
							justify-content: flex-start;
							flex-direction: column;
							margin: 50px 20px;

							li {
									margin-bottom: 16px;

									a {
											font-size: 16px;
									}
							}
					}
			}
	}

	.header__body-link {
			text-decoration: none;
			color: #2D3646;
			font-weight: 500;
			font-size: 16px;

			&:hover {
					color: #0E32F5;
					cursor: pointer;
			}

			&.router-link-exact-active {
					color: #0E32F5;
			}
	}

	.header__action-btn {
		max-width: 61px;

		&:first-child {
			max-width: 67px;
			margin-right: 8px;
		}


	}

	.header__body-block._mobile {
		.header__action-btn {
			max-width: none;
		}
	}

	.header__body-buttons {
			margin-left: 16px;
	}

	.header__body-buttons._right {
			margin-left: auto;
	}
	.header__body-buttons > *:not(:last-child) {
			margin-right: 5px;
			margin-bottom: 5px;
	}

	.header__content {
			-webkit-box-flex: 1;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			width: 1%;
			padding-left: 24px;
			padding-right: 24px;
	}

	.header__content-center {
			width: 498px;
			padding-left: 24px;
			padding-right: 24px;
			margin-left: auto;
			margin-right: auto;
	}

	.header__button {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 10;
			display: none;
	}

	@media screen and (max-width:1152px) {
			.header__content {
					padding-left: 16px;
					padding-right: 16px;
			}
	}

	@media screen and (max-width:1130px) {
			.header__wrapper {
					padding-top: 16px;
					padding-bottom: 16px;
					height: 101px;
					position: relative;
					z-index: 1;
			}

			.header__content-center {
					display: none;
			}

			.header__side-mobile {
					position: absolute;
					top: 50%;
					-webkit-transform: translateY(-50%);
					transform: translateY(-50%);
					right: 44px;
			}

			.header__body {
					overscroll-behavior-y: contain;
					overflow: hidden;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: vertical;
					-webkit-box-direction: normal;
					-ms-flex-direction: column;
					flex-direction: column;
					position: fixed;
					top: 56px;
					right: 0;
					width: 100%;
					height: calc(100% - 56px);
					background: #fff;
					z-index: 90;
					-webkit-transition: opacity .25s ease 0s,visibility .25s ease 0s;
					transition: opacity .25s ease 0s,visibility .25s ease 0s;
					will-change: opacity,visibility;
					opacity: 0;
					visibility: hidden;
			}

			.header__body._active {
					opacity: 1;
					visibility: visible;
			}

			.header__body-content {
					width: 100%;
					padding: 10px 0;
					-webkit-overflow-scrolling: touch;
					-webkit-box-flex: 100;
					-ms-flex-positive: 100;
					flex-grow: 100;
					overflow-y: auto;
					overflow-x: hidden;
			}

			.header__body-footer {
					width: 100%;
					-ms-flex-negative: 0;
					flex-shrink: 0;
					border-top: 1px solid #eee;
					padding: 16px 44px;
			}

			.header__button {
					display: block;
					margin-right: 30px;
			}
	}

	@media screen and (max-width:480px) {
			.header {
				max-width: 100vw;
			}
			.header__wrapper {
					padding-top: 16px;
					padding-bottom: 16px;
					height: 56px;
			}
			.header__side-mobile {
					right: 16px;
			}

			.header__body-footer {
					padding-left: 16px;
					padding-right: 16px;
			}

			.header__button {
					margin-right: 0;
			}

			.header__body-link {
				font-size: 14px;
			}
	}

  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 101px;
    z-index: 10000;

    @media screen and (max-width: 1130px) {
      width: 100%;
      height: unset;
    }
  }
}
</style>

